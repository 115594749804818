import React, { useState } from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link } from "gatsby"

//animation
import { Fade } from "react-reveal"

// svg decorations
// import "@components/svg-decorations/svg-decorations.scss"

import Sticky from "react-sticky-el"
import _ from "lodash"

import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"

const plans = [
	{
		name: "Enterprise Plan",
		description:
			"Designed for businesses where your average target is worth $20,000+ LTV. LeadRoll consistently produces ridiculous ROI in 6 months, or less.",
		slogan: "Sales Appointments Guaranteed",
		hours: "Avg 600+ Hours Saved/mo per sales rep",
		monthly: 7000,
		annual: 6000,
		url: "enterprise-plan",
		usage: [
			{
				title: "Multi-stage Voicemail Drops",
				limit: "Unlimited",
			},
			{
				title: "Customized Images in Outbound Campaigns",
				limit: "Unlimited",
			},
			{
				title: "Appointment Setting Sales Rep",
				limit: "Unlimited",
			},
			{
				title: "Personalized Email First Line",
				limit: "Unlimited",
			},
			{
				title: "A.I. Scheduling Assistant",
				limit: "Unlimited",
			},
			{
				title: "Direct Dial Numbers",
				limit: "Unlimited",
			},
			{
				title: "Technology Based Lead Searches",
				limit: "Unlimited",
			},
			{
				title: "Manual lead qualification/vetting",
				limit: "Unlimited",
			},
			{
				title: "Appointment tracking",
				limit: "Unlimited",
			},
			{
				title: "Pre-meeting reminders",
				limit: "Unlimited",
			},
			{
				title: "Direct calendar bookings & sync",
				limit: "Unlimited",
			},
			{
				title: "Up To 80h of Manual Lead Chasing per month",
				limit: "Unlimited",
			},
		],
		essentials: [
			{
				title: "50-70 Prospects Per Day [1500+/m]",
				limit: "Unlimited",
			},
			{
				title: "Lead list built and approved",
				limit: "Unlimited",
			},
			{
				title: "Outreach campaigns written and approved",
				limit: "Unlimited",
			},
			{
				title: "Email outbound prospecting & follow-up",
				limit: "100mb",
			},
			{
				title: "Gather current and past company urls",
				limit: "100mb",
			},
			{
				title: "Validated business emails",
				limit: "100mb",
			},
			{
				title: "Enterprise grade VPN login protection",
				limit: "100mb",
			},
			{
				title: "Monthly video reporting",
				limit: "100mb",
			},
			{
				title: "Dmark, Dkim, SPF record verification & installment",
				limit: "100mb",
			},
			{
				title: "Enrich & track entire prospect list",
				limit: "100mb",
			},
			{
				title: "Social Profile Optimization Guide",
				limit: "100mb",
			},
			{
				title: "Current client exclusions",
				limit: "100mb",
			},
		],
		advanced: [
			{
				title: "Split testing copy monthly",
				limit: "100mb",
			},
			{
				title: "Unlimited messaging update requests",
				limit: "100mb",
			},
			{
				title: "Spam monitoring/fixing",
				limit: "100mb",
			},
			{
				title: "Full domain prep and warmup",
				limit: "100mb",
			},
			{
				title: "A.I. lead response qualification",
				limit: "100mb",
			},
			{
				title: "Logging email responses",
				limit: "100mb",
			},
			{
				title: "Advanced metrics dashboard",
				limit: "100mb",
			},
		],
	},
]

const usage = plans.map(item => item.usage)
const essentials = plans.map(item => item.essentials)
const advanced = plans.map(item => item.advanced)
const filterUsage = _.uniqBy(usage, "title")
const filterEssentials = _.uniqBy(essentials, "title")
const filterAdvanced = _.uniqBy(advanced, "title")

const EnterprisePlanPage = ({ location }) => {
	const [isToggled, setToggled] = useState(false)
	const toggleTrueFalse = () => setToggled(!isToggled)

	return (
		<Layout location={location}>
			<SEO title="Enterprise Plan" />
			<div className="py-100 p-relative" id="pricing">
				<img className="oval-svg" src={oval1} alt="Oval svg" />
				<img className="oval-2" src={oval2} alt="Oval svg" />
				<img className="oval-3" src={oval3} alt="Oval svg" />
				<div className="container">
					<h1 className="text-primary-gradient text-center pb-5">
						Enterprise Plan
					</h1>
					<div className="switch-container d-flex align-items-center justify-content-center">
						<span
							className={`font-weight-bold ${isToggled ? "text-muted" : ""}`}
						>
							Retail
						</span>
						<label class="switch mb-0 mx-2" htmlFor="toggleTrueFalse">
							<input type="checkbox" onChange={toggleTrueFalse} />
							<span className="slider round"></span>
						</label>
						<span
							className={`font-weight-bold ${isToggled ? "" : "text-muted"}`}
						>
							Incentive Base
						</span>
					</div>
					<div className="row">
						<div className="col-lg-4 mb-5">
							<Sticky
								className="sticky-wrapper"
								stickyClassName="is-sticky"
								stickyStyle={{ transform: "unset", zIndex: "10" }}
							>
								<div className="plans">
									<div className="row justify-content-center">
										{plans.map((item, idx) =>
											idx !== 2 ? (
												<div className="col-12 mb-4">
													<Fade className="h-100">
														<div className="offer-content-wrapper h-100">
															<div className="text-center px-3 pb-3">
																<h3 className="text-primary-gradient">
																	{item.name}
																</h3>
															</div>
															<div class="feature-content px-4 pb-3 text-center">
																<p class="feature-item">{item.description}</p>
																<h5 className="font-weight-bold mb-0">
																	{item.slogan}
																</h5>
																<p className="font-weight-bold">{item.hours}</p>
															</div>
															<div className="price-and-cta">
																<div className="text-center feature-item price">
																	<h4 className="display-5 font-weight-bold text-primary-gradient mb-0">
																		<sup className="dollar-sup">$</sup>
																		{isToggled ? item.annual : item.monthly}
																	</h4>
																	<p className="font-weight-normal text-muted mb-0">
																		{isToggled ? (
																			<>
																				<span className="stick-plan text-primary-gradient">
																					${item.annual}{" "}
																				</span>
																				<span className="font-weight-normal text-muted mb-0">
																					Incentive Base
																				</span>
																			</>
																		) : (
																			<>
																				<span className="stick-plan text-primary-gradient">
																					${item.monthly}{" "}
																				</span>
																				<span className="font-weight-normal text-muted mb-0">
																					Retail
																				</span>
																			</>
																		)}
																	</p>
																</div>
																<div className="text-center px-3 pt-4">
																	<Link
																		to="/"
																		className={`btn btn-primary btn-lg shadow-none btn-red ${
																			idx === 1 ? "btn-red" : ""
																		}`}
																	>
																		Get A Price
																	</Link>
																</div>
															</div>
														</div>
													</Fade>
												</div>
											) : (
												<div className="col-md-4 mb-4">
													<Fade className="h-100">
														<div className="offer-content-wrapper h-100 bg-primary-gradient d-flex flex-column justify-content-center">
															<div class="coming-content px-4 pb-3 text-center">
																<h3 className="text-white">{item.name}</h3>
																<h5 class="text-white-50">
																	{item.description}
																</h5>
															</div>
														</div>
													</Fade>
												</div>
											)
										)}
									</div>
								</div>
							</Sticky>
						</div>
						<div className="col-lg-8 pl-md-5">
							<div className="row align-items-start">
								<div className="col-12">
									<h5 className="mb-4 mt-3">Featured</h5>
									<hr />
								</div>
								<div className="col-12">
									<div className="plan-usage">
										{filterUsage[0].map((item, idx) => (
											<p className="my-2">
												<img
													className="mr-1"
													src={require("@images/001-check.svg")}
													width={20}
													alt="checked"
												/>{" "}
												{item.title}
											</p>
										))}
									</div>
								</div>
								<div className="col-md-6 d-none">
									<div className="plan-limits">
										<div className="row">
											{plans.map(item => (
												<div className="col-4 px-4">
													{item.usage.map(item => (
														<p>{item.limit}</p>
													))}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className="row align-items-start">
								<div className="col-12">
									<h5 className="mb-4 mt-3">Essentials</h5>
									<hr />
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-md-12">
											{filterEssentials[0].map((item, idx) => (
												<p className="my-2">
													<img
														className="mr-1"
														src={require("@images/001-check.svg")}
														width={20}
														alt="checked"
													/>{" "}
													{item.title}
												</p>
											))}
										</div>
										{plans.map(item => (
											<div className="col-md-8 px-4 d-none">
												{item.essentials.map(item => (
													<p className="my-2">{item.limit}</p>
												))}
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="row align-items-start">
								<div className="col-12">
									<h5 className="mb-4 mt-3">Advanced</h5>
									<hr />
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-md-12">
											{filterAdvanced[0].map((item, idx) => (
												<p className="my-2">
													<img
														className="mr-1"
														src={require("@images/001-check.svg")}
														width={20}
														alt="checked"
													/>{" "}
													{item.title}
												</p>
											))}
										</div>
										{plans.map(item => (
											<div className="col-md-8 px-4 d-none">
												{item.advanced.map(item => (
													<p className="my-2">{item.limit}</p>
												))}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default EnterprisePlanPage
